.react-calendar {
    width: 100%;
    max-width: 750px;
    background: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    border-radius: 0.5rem;
    margin-top: 0.5rem;
}

.react-calendar--doubleView {
    display: none;
    /* Hide double view on small screens */
}

.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
    flex: 1 1 50%;
    margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}


.react-calendar__navigation {
    display: flex;
    height: 2.75rem;
    margin-bottom: 1rem;
    background: #FFFFFF;
}

.react-calendar__navigation button {
    background: transparent;
    font-size: 1.5em;
    font-weight: 600;
    min-width: 2.75rem;
}

.react-calendar__navigation button:disabled {
    background-color: transparent;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
    text-align: center;
    font-size: 1rem;
    font-weight: 800;
    color: #7096D1;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
}

.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font: inherit;
    font-size: 0.75em;
    font-weight: bold;
}

.react-calendar__month-view__days__day {
    font-size: 0.75em;
    margin: 0.3125rem 2.5rem;
    min-width: auto;
}

.react-calendar__month-view__days__day--weekend {
    color: #333333;
}

.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__decade-view__years__year--neighboringDecade,
.react-calendar__century-view__decades__decade--neighboringCentury {
    color: #B9B9B9;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}

.react-calendar__tile {
    max-width: 100%;
    padding: 0.625rem 0.4167rem;
    background: none;
    text-align: center;
    line-height: 1rem;
    font: inherit;
    font-size: 0.833em;
    position: relative;
    /* Needed to position the pseudo-element */
    display: flex;
    /* Centers the content */
    justify-content: center;
    /* Centers horizontally */
    align-items: center;
    /* Centers vertically */
}

.react-calendar__tile:disabled {
    background-color: transparent;
    color: #B9B9B9;
}

.react-calendar__tile--hasActive,
.react-calendar__tile--active {
    position: relative;
    /* Establish a stacking context */
    color: white;
    z-index: 1;
    /* Ensure the tile itself is above the pseudo-element */
}

.react-calendar__tile:enabled::after,
.react-calendar__tile--active::after,
.react-calendar__tile--hasActive::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 45%;
    height: 85%;
    background-color: #7096D1;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    /* Positioned underneath the text */
}


.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: transparent;
}

.react-calendar__tile--unavailable {
    background: #EA877A;
    color: #333;
    text-decoration: line-through;
}

/* Styles for the start date indicator */
.react-calendar__tile--rangeStart {
    color: white;
    /* Ensure text color is white */
}

/* Styles for the end date indicator */
.react-calendar__tile--rangeEnd {
    color: white;
    /* Ensure text color is white */
}

.react-calendar__tile--range {
    background: transparent;
}

.react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
}

/* Adjustments for better touch interaction */
.react-calendar__navigation button,
.react-calendar__tile {
    padding: 1.25rem;
    /* Larger padding for easier touch */
}

/* Ensure compatibility with older browsers */
.react-calendar--doubleView .react-calendar__viewContainer {
    flex-wrap: wrap;
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
    flex: 1 1 50%;
    /* Ensure that view containers take up half the space */
    margin: 0.5em;
}

.special-date {
    position: relative;
    color: white;
    z-index: 1;
}

.selected-date {
    position: relative;
    color: white; /* Text color */
    z-index: 1;
}

.selected-date.react-calendar__tile--hasActive::after,
.selected-date.react-calendar__tile--active::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 45%;
    height: 85%;
    background-color: #fcd34d;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}

.special-date.react-calendar__tile--hasActive::after,
.special-date.react-calendar__tile--active::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 45%;
    height: 85%;
    background-color: #EA877A;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}

/* .special-date.react-calendar__tile:disabled {
    color: white;
} */

.date-range {
    position: relative;
    color: black;
    z-index: 1;
}

.date-range.react-calendar__tile--hasActive::after,
.date-range.react-calendar__tile--active::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 45%;
    height: 85%;
    background-color: #ffffff;
    border: 2px solid #7096D1; /* Add a blue ring */
    border-radius: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}

.clicked-date {
    position: relative;
    color: white;
    z-index: 1;
}

.clicked-date.react-calendar__tile--hasActive::after,
.clicked-date.react-calendar__tile--active::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 45%;
    height: 85%;
    background-color: #7096D1;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}


/* Mobile-friendly adjustments */
@media (max-width: 768px) {
    .react-calendar {
        width: 100%;
        /* Use the full width on smaller screens */
        margin-top: 0.25rem;

        border-radius: 0.25rem;
        /* Adjust for smaller screens */
    }

    .react-calendar__navigation button,
    .react-calendar__tile {
        padding: 1rem;
        /* Increase padding for easier touch */
    }

    .react-calendar__month-view__days__day,
    .react-calendar__tile {
        font-size: 0.875em;
        /* Adjust font size for better readability */
    }

    .react-calendar__month-view__weekdays {
        font-size: 14px;
        /* Adjust weekday labels for smaller screens */
    }

    .react-calendar__navigation {
        height: 2.5rem;
        /* Adjust navigation bar height */
    }

    .react-calendar__navigation button {
        font-size: 1.25em;
        /* Adjust button font size for better visibility */
    }

    /* Hide double view on very small screens */
    .react-calendar--doubleView {
        display: none;
    }

    /* For better spacing in month view */
    .react-calendar__month-view__days__day {
        margin: 0.125rem 1.25rem;
        /* Adjust margins */
    }

    .react-calendar__tile:enabled::after,
    .react-calendar__tile--active::after,
    .react-calendar__tile--hasActive::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 45%;
        height: 85%;
        background-color: #7096D1;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
        /* Positioned underneath the text */
    }

    .special-date.react-calendar__tile--hasActive::after,
    .special-date.react-calendar__tile--active::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 45%;
        height: 85%;
        background-color: #EA877A;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
        /* Positioned underneath the text */
    }

    .selected-date.react-calendar__tile--hasActive::after,
    .selected-date.react-calendar__tile--active::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 45%;
        height: 85%;
        background-color: #fcd34d;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
    }

    /* .special-date.react-calendar__tile:disabled {
        color: white;
    } */

    .clicked-date.react-calendar__tile--hasActive::after 
    .clicked-date.react-calendar__tile--active::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 45%;
        height: 85%;
        background-color: #3fe10e;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
    }
}