@tailwind base;
@tailwind components;
@tailwind utilities;

@import "@fontsource/poppins";

@layer utilities {
    .scrollbar::-webkit-scrollbar {
        width: 15px;
        height: 5px;
    }

    .scrollbar::-webkit-scrollbar-track {
        border-radius: 100vh;
        background: #f3f4f6;
    }

    .scrollbar::-webkit-scrollbar-thumb {
        background: gray;
        border-radius: 100vh;
        border: 3px solid #f6f7ed;
    }

    .scrollbar::-webkit-scrollbar-thumb:hover {
        background: gray;
    }
}

@font-face {
    font-family: "Dosis";
    src: local("Dosis-Regular"),
        url("./fonts/Dosis/Dosis-Regular.ttf") format("truetype");
}

.Dosis {
    font-family: Dosis;
}

@font-face {
    font-family: "DosisBold";
    src: local("Dosis-Bold"),
        url("./fonts/Dosis/Dosis-Bold.ttf") format("truetype");
}

.DosisBold {
    font-family: DosisBold;
}

@font-face {
    font-family: "NotoSans";
    src: local("NotoSans-Regular"),
        url("./fonts/NotoSans/NotoSans-Regular.ttf") format("truetype");
}

.NotoSans {
    font-family: NotoSans;
}

.bg-gradient-radial {
    background-image: linear-gradient(105deg,
            hsl(226deg 29% 91%) 7%,
            hsl(167deg 25% 92%) 46%,
            hsl(160deg 22% 90%) 68%,
            hsl(209deg 29% 85%) 100%);
}

.bg-gradient {
    background: rgb(106, 125, 156);
    background: radial-gradient(circle, rgba(106, 125, 156, 1) 0%, rgba(88, 109, 143, 1) 100%);
}

h1 {
    @apply text-h1 font-bold font-poppins text-mi-black break-words;
  }
  
h2 {
    @apply text-h2 font-semibold font-poppins text-mi-black break-words;
}

h3 {
    @apply text-h3 font-semibold font-poppins text-mi-black break-words;
}

h4 {
    @apply text-h4 font-semibold font-poppins text-mi-black break-words;
}

h5 {
    @apply text-h5 font-semibold font-poppins text-mi-black break-words;
}

h6 {
    @apply text-h6 font-semibold font-poppins text-mi-black break-words;
}

p {
    @apply text-h3 font-normal text-black break-words;
}

.aspect-ratio-box {
    position: relative;
    width: 100%;
    padding-top: 100%; /* 1:1 Aspect Ratio */
  }
  
.aspect-ratio-box > * {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.PhoneInputInput {
    background-color: inherit;
    border-radius: inherit;
    padding: inherit;
}